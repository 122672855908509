import moment from 'moment'
import React from 'react'
import {convertToMilliseconds} from '../utils/convertToMiliseconds'

type Props = {
  date: Date
  tag: string
  removeAfter: string | number
}

export const NewTag: React.FC<Props> = ({date, tag, removeAfter}) => {
  const [showTag, setShowTag] = React.useState(false)

  React.useEffect(() => {
    show()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date])

  const show = () => {
    if (date == null) {
      return false
    }

    const now = moment()
    const creationTime = moment(date)
    const elapsed = now.diff(creationTime, 'milliseconds')
    const remove = convertToMilliseconds(removeAfter)
    const show = elapsed < remove
    setShowTag(show)
  }
  return (
    <>
      <span className='d-inline-flex gap-2'>
        {date && showTag && (
          <div className='text-center cursor-pointer'>
            <span className='badge rounded-pill badge-light-danger'>{tag}</span>
          </div>
        )}
      </span>
    </>
  )
}
