import {formatDate, truncate} from 'app/shared/utils/utils'
import {useFormik} from 'formik'
import React, {FC, useEffect} from 'react'

type BlogitemProps = {
  title: string
  description: string
  date: Date
  image: string
  goTo: Function
  showEdit: boolean
  isPublished: boolean
  changeState: Function
}

const Blogitem: FC<BlogitemProps> = ({
  title,
  description,
  date,
  image,
  goTo,
  showEdit,
  isPublished,
  changeState,
}) => {
  const fallbackImage = 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg'

  const [check, setCheck] = React.useState(isPublished)

  useEffect(() => {
    setCheck(isPublished)
  }, [isPublished])

  const formik = useFormik({
    initialValues: {
      publichecked: check,
    },
    onSubmit: (values) => {
      changeState(values.publichecked)
    },
  })

  return (
    <div>
      <div className='row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-250px position-relative'>
        <div className='col p-4 d-flex flex-column position-static'>
          <h3 className='mb-0 fs-2tx fw-bold'>{title}</h3>
          <div className='mb-1 text-muted'>{formatDate(date)}</div>
          <p className='card-text mb-auto'>{truncate(description, 90)}</p>
          <a onClick={() => goTo()} className='icon-link icon-link-hover stretched-link'>
            Continuar leyendo...
          </a>
          {showEdit && (
            <div className='form-check form-switch form-check-custom form-check-solid float-end mt-2 position-absolute switch'>
              <input
                checked={formik.values.publichecked}
                className='form-check-input'
                type='checkbox'
                id='flexSwitchDefault'
                onChange={(e: any) => {
                  formik.setFieldValue('publichecked', e.target.checked)
                  formik.handleSubmit()
                }}
              />
              <label className='form-check-label' htmlFor='flexSwitchDefault'></label>
            </div>
          )}
        </div>
        <div className='col-auto d-none d-lg-block h-250px'>
          <img
            src={image}
            alt={title}
            className='bd-placeholder-img h-100 w-200px'
            onError={(e) => (e.currentTarget.src = fallbackImage)}
          />
        </div>
      </div>
    </div>
  )
}

export default Blogitem
