const MS_REGEX = /([0-9]+(?:\.[0-9]+)?)(ms|s|min|m|h)/

// export type AsMilliseconds =
// 	| number
// 	| `${number}ms`
// 	| `${number}s`
// 	| `${number}min`
// 	| `${number}m`
// 	| `${number}h`;

/**
 * Convert a time unit to milliseconds.
 * @param ms The time to convert.
 * @returns The time in milliseconds.
 *
 * @example
 * ```ts
 * convertToMilliseconds("100ms"); // 100
 * convertToMilliseconds("1s"); // 1000
 * convertToMilliseconds("1m"); // 60000
 * convertToMilliseconds("1h"); // 3600000
 * convertToMilliseconds("hello"); // NaN
 * ```
 */
export function convertToMilliseconds(ms: string | number): number {
  if (typeof ms === 'number') {
    return ms
  }

  if (ms == null) {
    return 0
  }

  const match = ms.match(MS_REGEX)

  if (!match) {
    throw new Error(`Invalid time format: ${ms}`)
  }
  const [, value, unit] = match

  switch (unit) {
    case 'ms':
      return parseFloat(value)
    case 's':
      return parseFloat(value) * 1000
    case 'm':
      return parseFloat(value) * 1000 * 60
    case 'min':
      return parseFloat(value) * 1000 * 60
    case 'h':
      return parseFloat(value) * 1000 * 60 * 60
    default:
      throw new Error(`Invalid time format: ${ms}`)
  }
}
