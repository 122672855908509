import React from 'react'

type Props = {
  from: any
  to: any
}

export const TimeDiff: React.FC<Props> = ({from, to}) => {
  const time_diff = (endDate: any, fromDate: any): any => {
    if (endDate && fromDate) {
      const now = new Date()
      fromDate = fromDate < now ? now : fromDate
      const diffTime = Math.abs(fromDate - endDate)
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      if (diffDays === 0) {
        return 'Hoy'
      }
      let expiryDate = +new Date(endDate)
      let currentDate = +new Date(fromDate)
      let seconds = (expiryDate - currentDate) / 1000 // expiry Date - current time
      let sign = Math.sign(seconds)
      let suffix = 'Quedan' // if the time is yet to come.
      if (sign === -1) {
        seconds = Math.floor(seconds * sign) // removign the sign and the float part -25.5  = 25 seconds
        suffix = 'Hace' // if time is already expired.
      }
      const intervals: any = {
        año: 31536000,
        mes: 2592000,
        semana: 604800,
        dia: 86400,
        hora: 3600,
        minuto: 60,
        segundo: 1,
      }
      const allInterval = ['año', 'mes', 'semana', 'dia', 'hora', 'minuto', 'segundo']
      let counter
      for (let i of allInterval) {
        counter = Math.floor(seconds / intervals[i])
        let toReturn: string = ''
        // calculateion shown for for 2hour:51 minute = 171 minute = 10260 second
        if (counter > 0) {
          toReturn += calculateTime(counter, i) // this will give 2 hours
          let timeLeft = seconds - counter * intervals[i] // 3060 second
          let index = allInterval.indexOf(i) + 1 // get the index of next unit
          i = allInterval[index] // value of next unit = minute
          if (index > 6) {
            return suffix + toReturn // second ago for boundary case
          }
          counter = Math.floor(timeLeft / intervals[i]) // 3060 second = 51
          toReturn = suffix + ' ' + toReturn + ' ' + calculateTime(counter, i) // will calculate "2 hours 51 Minutes" from current time
          return toReturn
        }
      }
    }
    return endDate
  }

  const calculateTime = (counter: number, timeUnit: string) => {
    if (counter === 1) {
      return counter + ' ' + timeUnit // singular (1 hours ago)
    } else {
      let toReturn = counter + ' ' + timeUnit + 's '
      return toReturn // plural (2 hours ago)
    }
  }

  return <>{time_diff(to, from)}</>
}
