import React, {FC, useState} from 'react'
import axios from 'axios'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {Modal, Button, Image} from 'antd'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import clsx from 'clsx'
import {
  CompanyControllerServiceProxy,
  CreateCompanyDto,
} from 'app/services/service-proxies/service-proxies'

const companyClient = new CompanyControllerServiceProxy(undefined, axios)

type Props = {
  isModalVisible: boolean
  setIsModalVisible: (isModalVisible: boolean) => void
  onCreateLocation: () => void
}

const initialValues = {
  name: '',
  url: '',
  email: '',
  logoUrl: '',
}

const registrationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimo 3 caracteres')
    .max(50, 'Maximo 50 caracteres')
    .required('El primer nombre es requerido'),
  url: Yup.string().url('Debe ser una URL valida'),
  email: Yup.string().email('Debe ser un email valido').required('El email es requerido'),
  logoUrl: Yup.string().url('Debe ser una URL valida'),
})

export const CreateCompany: FC<Props> = ({isModalVisible, setIsModalVisible, onCreateLocation}) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const {confirm} = Modal

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      let data: CreateCompanyDto = new CreateCompanyDto()
      data.init(values)
      data.name = values.name.toUpperCase()
      confirm({
        title: 'Seguro que deseas agregar esta compañía?',
        icon: <ExclamationCircleOutlined />,
        content:
          'Al agregar una compañía aceptas que tienes permiso de parte de la compañía para publicar posiciones en su nombre',
        okText: 'Agregar',
        cancelText: 'Cancelar',
        onOk() {
          setLoading(true)
          companyClient
            .create(data)
            .then(() => {
              setLoading(false)
              setIsModalVisible(false)
              onCreateLocation()
              formik.resetForm()
            })
            .catch((err: any) => {
              setLoading(false)
              setSubmitting(false)
              setStatus(err.message)
            })
        },
      })
    },
  })

  return (
    <Modal
      title='Crear compañia'
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      okText='Editar'
      cancelText='Cancelar'
      destroyOnClose={true}
      footer={[
        <Button key='back' onClick={() => setIsModalVisible(false)}>
          Cancelar
        </Button>,
        <Button key='submit' type='primary' loading={loading} onClick={() => formik.handleSubmit()}>
          Guardar
        </Button>,
      ]}
    >
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
      >
        {/* begin::Form group name */}
        <div className='fv-row mb-7'>
          <label className='class="form-label fw-bolder text-dark fs-6 required'>Nombre</label>
          <input
            placeholder='Nombre'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('name')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.name && formik.errors.name,
              },
              {
                'is-valid': formik.touched.name && !formik.errors.name,
              }
            )}
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.name}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
        {/* begin::Form group email */}
        <div className='fv-row mb-7'>
          <label className='class="form-label fw-bolder text-dark fs-6 required'>
            Correo electronico
          </label>
          <input
            placeholder='Correo electronico'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.email && formik.errors.email,
              },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          <div id='companyEmail' className='form-text'>
            Tip: Asegurate de que el correo electronico sea valido, ya que sera el que se utilizara
            para hacerte llegar los curriculum vitae de los candidatos.
          </div>
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
        {/* begin::Form group url */}
        <div className='fv-row mb-7'>
          <label className='class="form-label fw-bolder text-dark fs-6'>Sitio Web (opcional)</label>
          <input
            placeholder='Sitio Web'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('url')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.url && formik.errors.url,
              },
              {
                'is-valid': formik.touched.url && !formik.errors.url,
              }
            )}
          />
          {formik.touched.url && formik.errors.url && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.url}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
        {/* begin::Form group logoUrl */}
        <div className='fv-row mb-7'>
          <label className='class="form-label fw-bolder text-dark fs-6'>
            Logo de la empresa (.jpg, .png) (opcional)
          </label>
          <div className='input-group mb-3'>
            <input
              placeholder='Logo de la empresa'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('logoUrl')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.logoUrl && formik.errors.logoUrl,
                },
                {
                  'is-valid': formik.touched.logoUrl && !formik.errors.logoUrl,
                }
              )}
            />
            <button
              className='btn btn-outline-primary'
              type='button'
              id='button-addon2'
              disabled={formik.values.logoUrl.length === 0}
              onClick={() => {
                setVisible(true)
              }}
            >
              Visualizar
            </button>
          </div>
          {formik.touched.logoUrl && formik.errors.logoUrl && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.logoUrl}</span>
              </div>
            </div>
          )}

          <div id='logoUrl' className='form-text'>
            Tip: Asegurate de que el servidor donde esté alojado el logo acepte{' '}
            <a
              href='https://es.wikipedia.org/wiki/Hotlinking'
              target='_blank'
              rel='noopener noreferrer'
            >
              hotlinks.
            </a>{' '}
            Si no sabes que es eso, no te preocupes, sube el logo a{' '}
            <a href='https://imgur.com/' target='_blank' rel='noopener noreferrer'>
              imgur
            </a>{' '}
            y pega la url aquí
          </div>
        </div>
        {/* end::Form group */}
      </form>
      <Image
        width={200}
        style={{display: 'none'}}
        src={formik.values.logoUrl}
        preview={{
          visible,
          src: formik.values.logoUrl,
          onVisibleChange: (value) => {
            setVisible(value)
          },
        }}
      />
    </Modal>
  )
}
