import React, {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {HeaderUserMenu} from '../../../partials'

const Topbar: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const navigate = useNavigate()

  return (
    <div className='d-none d-md-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
        {user && <HeaderUserMenu />}
        {/* {!user && (
          <a
            href='#/'
            onClick={() => navigate('/auth/login')}
            className='btn btn-primary btn-hover-scale me-5'
          >
            Inicia sesión
          </a>
        )} */}
      </div>
    </div>
  )
}

export {Topbar}
