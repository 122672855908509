/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

type Props = {
    className?: string
    title: string
    subtitle?: string,
    children: string | JSX.Element | JSX.Element[]
}

const Panel: React.FC<Props> = ({ className, title, subtitle, children }) => {

    return (
        <div className={`card shadow ${className}`}>
            {/* begin::Header */}
            <div className='card-header pt-5 border-bottom-dashed border'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>{title}</span>
                    {subtitle && <span className='text-muted fw-bold fs-7'>{subtitle}</span>}
                </h3>
                {/* <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 />
        </div> */}
            </div>
            <div className='card-body py-3'>
                {children}
            </div>
        </div>
    )
}

export { Panel }
