/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import axios from 'axios'
// import {Modal} from 'antd'
import {KTSVG} from '_metronic/helpers'
import {UserControllerServiceProxy} from 'app/services/service-proxies/service-proxies'
import {UserModel} from 'app/modules/auth/models/UserModel'
import {EditUser} from './EditUser'
import {Pagination} from 'app/shared/components/Pagination'
import {FilterDropdown} from 'app/shared/components/FilterDropdown'
import {useFormik} from 'formik'
import {Dropdown, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {addTdLabel} from '_metronic/helpers/TableResponsiveHelper'

export const UserPage = () => {
  const userClient = new UserControllerServiceProxy(undefined, axios)
  const [users, setUsers] = useState<UserModel[]>([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedUser, setSelectedUser] = useState<UserModel>()
  const [selectedFormInitialValues, setSelectedFormInitialValues] = useState<any>()
  const [totalRecords, setTotalRecords] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [keyword, setKeyword] = useState('')

  const getUsers = async () => {
    userClient.getAll(keyword, 10, currentPage).then((res) => {
      res.data.Items.forEach((user: any) => (user.created_at = new Date(user.created_at)))
      setUsers(res.data.Items)
      setTotalRecords(res.data.TotalCount)
      addTdLabel()
    })
  }

  useEffect(() => {
    getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, keyword])

  const onEditUser = () => {
    getUsers()
  }

  const onPageChange = (page: number) => {
    setCurrentPage(page)
  }

  const showModelEditUser = (user: UserModel) => {
    setIsModalVisible(true)
    setSelectedUser(user)
    setSelectedFormInitialValues({
      _id: user._id,
      name: user.name,
      lastName: user.lastName,
      email: user.email,
      roles: user.roles[0],
    })
  }

  const initialValues = {
    keyword: '',
  }

  const form = useFormik({
    initialValues,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      const {keyword} = values
      setKeyword(keyword)
    },
  })

  return (
    <>
      <div className='content flex-row-fluid'>
        <div className='row'>
          <div className={`card card-xl-stretch mb-xl-8`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Listado de Usuarios</span>
                {/* <span className='text-muted fw-bold fs-7'>Pending 10 tasks</span> */}
              </h3>
              <div className='card-toolbar'>
                {/* begin::Menu */}
                <OverlayTrigger
                  delay={{hide: 450, show: 300}}
                  overlay={(props) => <Tooltip {...props}>Filtros</Tooltip>}
                  placement='bottom'
                >
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                  </button>
                </OverlayTrigger>
                {/* begin::Menu 1 */}
                <FilterDropdown>
                  <form onSubmit={form.handleSubmit} autoComplete='off'>
                    <div className='mb-10'>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='nombre, apellido, correo'
                        {...form.getFieldProps('keyword')}
                      />
                    </div>
                    <div className='d-flex justify-content-end'>
                      <button
                        type='reset'
                        onClick={() => {
                          form.resetForm()
                          setKeyword('')
                        }}
                        className='btn btn-sm btn-white btn-active-light-primary me-2'
                        data-kt-menu-dismiss='true'
                      >
                        Limpiar
                      </button>

                      <button
                        type='submit'
                        className='btn btn-sm btn-primary'
                        data-kt-menu-dismiss='true'
                      >
                        Aplicar
                      </button>
                    </div>
                  </form>
                </FilterDropdown>
                {/* end::Menu 1 */}
                {/* end::Menu */}
              </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-hover align-middle gs-0 gy-5'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bolder fs-6 border-bottom border-gray-200 text-gray-800'>
                      <th>Nombre</th>
                      <th>Correo electronico</th>
                      <th>Fecha de creacion</th>
                      <th>Role</th>
                      <th>Plataforma</th>
                      <th></th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {users &&
                      users.map((user) => (
                        <tr key={user._id}>
                          <td>
                            {user.name}{' '}
                            {(user.lastName || !user.lastName.includes('n/a')) && user.lastName}
                          </td>
                          <td>{user.email}</td>
                          <td>{user.created_at?.toLocaleDateString()}</td>
                          <td>{user.roles}</td>
                          <td>
                            {user.googleUid ? (
                              <span className='badge badge-primary'>Google</span>
                            ) : (
                              <span className='badge badge-dark'>Interno</span>
                            )}
                          </td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                                Opciones
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item onClick={() => showModelEditUser(user)}>
                                  <i className='fas fa-pen-alt me-2'></i> Editar
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}
              </div>
              {/* end::Table container */}
            </div>
            <Pagination
              pageSize={10}
              totalRecorsds={totalRecords}
              currentPage={currentPage}
              onPageChange={(page) => onPageChange(page)}
            />
          </div>
        </div>
      </div>
      <EditUser
        user={selectedUser}
        initialValues={selectedFormInitialValues}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        onEditUser={onEditUser}
      />
    </>
  )
}
