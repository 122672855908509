/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {Tab, Tabs} from 'react-bootstrap'
import {DatePicker, Descriptions, message, Modal} from 'antd'
import {
  ApproveJobInput,
  JobControllerServiceProxy,
} from 'app/services/service-proxies/service-proxies'
import axios from 'axios'
import moment from 'moment'

const {RangePicker} = DatePicker

type Props = {
  setIsModalVisible: (isModalVisible: boolean) => void
  isModalVisible: boolean
  onApproveJob: () => void
  initFormValues: any
}

export const ManageJob: FC<Props> = ({
  setIsModalVisible,
  onApproveJob,
  isModalVisible,
  initFormValues,
}) => {
  const [showMore, setShowMore] = React.useState(false)
  const jobClient = new JobControllerServiceProxy(undefined, axios)
  const [dates, setDates] = React.useState<any>([])
  const [hackValue, setHackValue] = React.useState<any>()
  const [value, setValue] = React.useState<any>()

  const onOpenChange = (open: boolean) => {
    if (open) {
      setHackValue([])
      setDates([])
    } else {
      setHackValue(undefined)
    }
  }

  function disabledDate(current: any) {
    return current && current < moment().add(-1, 'day').endOf('day')
  }

  useEffect(() => {
    if (initFormValues?.publishedFrom && initFormValues?.publishedTo) {
      setValue([moment(initFormValues?.publishedFrom), moment(initFormValues?.publishedTo)])
    }
  }, [initFormValues])

  const save = () => {
    if (dates.length === 0) {
      message.error('Por favor selecciona un rango de fecha')
      return
    }
    let data: ApproveJobInput = new ApproveJobInput()
    data.jobId = initFormValues._id
    data.isApproved = true
    data.publishedFrom = dates[0].toDate()
    data.publishedTo = dates[1].toDate()

    jobClient.setVisibility(initFormValues._id, data).then(() => {
      onApproveJob()
      setIsModalVisible(false)
      message.success('Se ha aprobado la oferta de trabajo')
    })
  }

  return (
    <>
      <Modal
        title='Basic Modal'
        visible={isModalVisible}
        onOk={save}
        onCancel={() => setIsModalVisible(false)}
        width={1000}
      >
        <Tabs
          defaultActiveKey='home'
          id='uncontrolled-tab-example'
          className='nav-line-tabs nav-line-tabs-2x mb-5 fs-6'
        >
          <Tab eventKey='home' title='Datos de publicacion'>
            <label className='class="form-label fw-bolder text-dark fs-3 required'>
              Vigencia
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Este es el rango de fechas en el que estara visible la publicacion'
              ></i>
            </label>
            <RangePicker
              ranges={{
                Hoy: [moment(), moment()],
                'Esta Semana': [moment().startOf('week'), moment().endOf('week')],
                'Este Mes': [moment().startOf('month'), moment().endOf('month')],
                'Una Semana': [moment(), moment().add(1, 'week')],
                'Dos Semanas': [moment(), moment().add(2, 'week')],
                'Un Mes': [moment(), moment().add(30, 'days')],
              }}
              disabledDate={disabledDate}
              size='large'
              style={{width: '100%'}}
              value={hackValue || value}
              onCalendarChange={(val) => setDates(val)}
              onChange={(val) => setValue(val)}
              onOpenChange={onOpenChange}
            />
          </Tab>
          <Tab eventKey='profile' title='Datos Generales'>
            <Descriptions title='Información de la vacante' layout='vertical' bordered>
              <Descriptions.Item label='Titulo'>{initFormValues?.title}</Descriptions.Item>
              <Descriptions.Item label='Categoria'>
                {initFormValues?.category?.name}
              </Descriptions.Item>
              <Descriptions.Item label='Locacion'>
                {initFormValues?.location?.name}
              </Descriptions.Item>
              <Descriptions.Item label='Jornada'>
                {initFormValues?.hyreType?.name}
              </Descriptions.Item>
              <Descriptions.Item label='Usuario Creador' span={2}>
                {initFormValues?.postedBy?.name}
              </Descriptions.Item>
              <Descriptions.Item label='Tipo' span={3}>
                {initFormValues?.isRemote ? (
                  <span className='badge badge-primary'>Remoto</span>
                ) : (
                  <span className='badge badge-warning'>Local</span>
                )}
              </Descriptions.Item>
              <Descriptions.Item label='Fecha de creacion'>
                {initFormValues?.created_at?.toLocaleDateString()}
              </Descriptions.Item>
              <Descriptions.Item label='Salario'>{initFormValues?.salary}</Descriptions.Item>
              <Descriptions.Item label='Compañia'>
                {initFormValues?.company?.name}
              </Descriptions.Item>
              <Descriptions.Item label='Descripcion'>
                {showMore ? (
                  <div
                    style={{fontSize: '16px', fontWeight: 400}}
                    dangerouslySetInnerHTML={{__html: initFormValues?.description}}
                  />
                ) : (
                  <div
                    style={{fontSize: '16px', fontWeight: 400}}
                    dangerouslySetInnerHTML={{
                      __html: initFormValues?.description?.substring(0, 250) + ' ...',
                    }}
                  />
                )}
                <a className='text-primary' onClick={() => setShowMore(!showMore)}>
                  {showMore ? 'Mostrar menos' : 'Mostrar mas'}
                </a>
              </Descriptions.Item>
            </Descriptions>
          </Tab>
        </Tabs>
      </Modal>
    </>
  )
}
