/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Image, message, Modal} from 'antd'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import {KTSVG} from '../../../../_metronic/helpers'
import {
  CategoriesControllerServiceProxy,
  CompanyControllerServiceProxy,
  GetCategoryDto,
  GetLocationDto,
  HireTypeControllerServiceProxy,
  JobControllerServiceProxy,
  LocationsControllerServiceProxy,
  UpdateJobDto,
} from 'app/services/service-proxies/service-proxies'
import axios from 'axios'
import {CreateJob} from './CreateJob'
import {UserModel} from 'app/modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from 'setup'
import {APP_COMPANY_DEFAULT_LOGO} from 'setup/app-constants'
import {Pagination} from 'app/shared/components/Pagination'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {FilterDropdown} from 'app/shared/components/FilterDropdown'
import {useFormik} from 'formik'
import {TimeAgo} from 'app/shared/components/TimeAgo'
import {EditJob} from './EditJob'
import {useNavigate} from 'react-router-dom'
import {Helmet} from 'react-helmet'

export interface IFilters {
  keyword?: string
  location?: string
  hyreType?: string
  category?: string
  postedBy?: string
  company?: string
  isActive?: boolean
  isRemote?: boolean
}

export const Jobs = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [categories, setCategories] = React.useState([])
  const [locations, setLocation] = React.useState([])
  const [companies, setCompanies] = useState<any[]>([])
  const [hyreTypes, setHyreTypes] = useState<any[]>([])
  const [jobs, setJobs] = React.useState<any[]>([])
  const [isModalCreateVisible, setIsModalCreateVisible] = useState(false)
  const [isModalEditVisible, setIsModalEditVisible] = useState(false)
  const [totalRecords, setTotalRecords] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [filters, setFilters] = useState<IFilters>({})
  const [editModel, setEditModel] = useState<any>(undefined)
  const navigate = useNavigate()

  const categoryClient = new CategoriesControllerServiceProxy(undefined, axios)
  const locationClient = new LocationsControllerServiceProxy(undefined, axios)
  const jobClient = new JobControllerServiceProxy(undefined, axios)
  const companyClient = new CompanyControllerServiceProxy(undefined, axios)
  const hyreTypeClient = new HireTypeControllerServiceProxy(undefined, axios)

  const pageSize = 5

  const {confirm} = Modal

  const getJobs = () => {
    jobClient
      .findAll(
        undefined,
        undefined,
        undefined,
        filters.isRemote,
        filters.company,
        user._id,
        filters.category,
        filters.hyreType,
        filters.location,
        filters.keyword,
        pageSize,
        currentPage
      )
      .then((res: any) => {
        res.data.Items.forEach((user: any) => (user.created_at = new Date(user.created_at)))
        setJobs(res.data.Items)
        setTotalRecords(res.data.TotalCount)
      })
  }

  useEffect(() => {
    if (currentPage === 1) {
      locationClient.getAllWithoutPagination().then((locations) => {
        setLocation(locations.data)
      })

      categoryClient.getAllWithoutPagination().then((res) => {
        setCategories(res.data)
      })

      companyClient.getAllWithoutPagination(user._id).then((res) => {
        res.data.forEach((user: any) => (user.created_at = new Date(user.created_at)))
        setCompanies(res.data)
      })

      hyreTypeClient.getAllWithoutPagination().then((res) => {
        setHyreTypes(res.data)
      })
    }

    getJobs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, filters])

  const initialValues: IFilters = {
    keyword: '',
    location: '',
    hyreType: '',
    category: '',
    postedBy: '',
    company: '',
    isActive: undefined,
    isRemote: undefined,
  }

  const form = useFormik({
    initialValues,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setFilters(values)
    },
  })

  const onEditLocations = () => {
    getJobs()
  }

  const toggleStateJob = (job: any) => {
    let model: UpdateJobDto = new UpdateJobDto()
    model.init(job)
    model.isActive = !model.isActive
    model.companyType = false
    model.category = job.category._id
    model.location = job.location._id
    model.hyreType = job.hyreType._id
    model.companyId = job.company._id

    confirm({
      title: `¿Quieres ${job.isActive ? 'inactivar' : 'activar'} esta compañía?`,
      icon: <ExclamationCircleOutlined />,
      content: `${job.isActive ? 'Inactivar' : 'Activar'} esta compañía`,
      okText: `${job.isActive ? 'Inactivar' : 'Activar'}`,
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        jobClient
          .update(model._id, model)
          .then((res) => {
            message.success('Actualizado correctamente')
            getJobs()
          })
          .catch((err) => {
            message.error(err.data.message)
          })
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>Tu Empleo Ideal | Ofertas de trabajo</title>
        <meta
          name='description'
          content='Explora nuestras ofertas de trabajo y encuentra oportunidades para tu empleo ideal. 
        Descubre empleos emocionantes en diversos sectores y haz avanzar tu carrera profesional.'
        />
      </Helmet>
      <div className='row gy-5 gx-xl-8'>
        <div className='col card card-xxl-stretch  mb-5 mb-xl-10'>
          {/* begin::Header */}
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Listado de vacantes</span>
            </h3>
            <div className='card-toolbar'>
              <button
                type='button'
                onClick={() => {
                  setIsModalCreateVisible(true)
                }}
                className='btn btn-sm btn-light'
              >
                Nueva vacante
              </button>
              <OverlayTrigger
                delay={{hide: 450, show: 300}}
                overlay={(props) => <Tooltip {...props}>Filtros</Tooltip>}
                placement='bottom'
              >
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary ms-5'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                </button>
              </OverlayTrigger>
              {/* begin::Menu 1 */}
              <FilterDropdown>
                <form onSubmit={form.handleSubmit} autoComplete='off'>
                  <div className='mb-10'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Título, Descripción'
                      {...form.getFieldProps('keyword')}
                    />
                  </div>
                  <div className='mb-10'>
                    <label className='form-label fw-bold'>Esta Activa:</label>
                    <div className='d-flex  justify-content-between'>
                      <div className='form-check  form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='radio'
                          {...form.getFieldProps('isActive')}
                          value={'all'}
                          defaultChecked={true}
                        />
                        <label className='form-check-label'>Todas</label>
                      </div>
                      <div className='form-check  form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='radio'
                          {...form.getFieldProps('isActive')}
                          value={'yes'}
                        />
                        <label className='form-check-label'>Si</label>
                      </div>
                      <div className='form-check  form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='radio'
                          {...form.getFieldProps('isActive')}
                          value={'no'}
                        />
                        <label className='form-check-label'>No</label>
                      </div>
                    </div>
                  </div>
                  <div className='mb-10'>
                    <label className='form-label fw-bold'>Categoría:</label>
                    <div className='form-floating'>
                      <select
                        className='form-select'
                        id='floatingSelect'
                        aria-label='Floating label select example'
                        {...form.getFieldProps('category')}
                      >
                        <option value='' disabled>
                          Selecciona una categoria
                        </option>
                        {categories.map((option: GetCategoryDto) => (
                          <option key={option._id} value={option._id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='mb-10'>
                    <label className='form-label fw-bold'>Localización:</label>
                    <div className='form-floating'>
                      <select
                        className='form-select'
                        id='floatingSelect'
                        aria-label='Floating label select example'
                        {...form.getFieldProps('location')}
                      >
                        <option value='' disabled>
                          Selecciona una localización
                        </option>
                        {locations.map((option: GetLocationDto) => (
                          <option key={option._id} value={option._id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='mb-10'>
                    <label className='form-label fw-bold'>Compañía:</label>
                    <div className='form-floating'>
                      <select
                        className='form-select'
                        id='floatingSelect'
                        aria-label='Floating label select example'
                        {...form.getFieldProps('company')}
                      >
                        <option value='' disabled>
                          Selecciona una compañía
                        </option>
                        {companies.map((option: any) => (
                          <option key={option._id} value={option._id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='mb-10'>
                    <label className='form-label fw-bold'>Jornada Laboral:</label>
                    <div className='form-floating'>
                      <select
                        className='form-select'
                        id='floatingSelect'
                        aria-label='Floating label select example'
                        {...form.getFieldProps('hyreType')}
                      >
                        <option value='' disabled>
                          Selecciona una jornada
                        </option>
                        {hyreTypes.map((option: any) => (
                          <option key={option._id} value={option._id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='mb-10'>
                    <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                      <input
                        type='checkbox'
                        className='form-check-input'
                        {...form.getFieldProps('isRemote')}
                        name='isRemote'
                      />
                      <label className='form-check-label'>¿Es un puesto remoto?</label>
                    </div>
                  </div>
                  <div className='d-flex justify-content-end'>
                    <button
                      type='reset'
                      onClick={() => {
                        form.resetForm()
                        setFilters(initialValues)
                      }}
                      className='btn btn-sm btn-white btn-active-light-primary me-2'
                      data-kt-menu-dismiss='true'
                    >
                      Limpiar
                    </button>

                    <button
                      type='submit'
                      className='btn btn-sm btn-primary'
                      data-kt-menu-dismiss='true'
                    >
                      Aplicar
                    </button>
                  </div>
                </form>
              </FilterDropdown>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-3'>
            <div className='tab-content'>
              <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
                {/* begin::Table container */}
                {jobs?.length > 0 && (
                  <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className='border-0'>
                          <th className='p-0 w-50px'></th>
                          <th className='p-0'></th>
                          <th className='p-0'></th>
                          <th className='p-0'></th>
                          <th className='p-0 '></th>
                          <th className='p-0'></th>
                          <th className='p-0 min-w-50px'></th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {jobs.map((job) => {
                          return (
                            <tr key={job._id}>
                              <td>
                                <div className='symbol symbol-45px me-2'>
                                  <span className='symbol-label'>
                                    <Image
                                      width={100}
                                      src={
                                        job?.company?.logoUrl
                                          ? job.company.logoUrl
                                          : APP_COMPANY_DEFAULT_LOGO.url
                                      }
                                    />
                                  </span>
                                </div>
                              </td>
                              <td>
                                <p style={{whiteSpace: 'nowrap'}}>
                                  <a
                                    onClick={() => {
                                      let model = {
                                        _id: job._id,
                                        companyType: 'existing',
                                        companyId: job.company?._id.toString(),
                                        companyName: '',
                                        companyUrl: '',
                                        companyEmail: '',
                                        companyLogoUrl: '',
                                        title: job.title,
                                        description: job.description,
                                        isRemote: job.isRemote,
                                        category: job.category._id.toString(),
                                        hyreType: job.hyreType._id.toString(),
                                        location: job.location._id.toString(),
                                        salary: job.salary ? job.salary : 0,
                                      }
                                      setEditModel(model)
                                      setIsModalEditVisible(true)
                                    }}
                                    className='text-dark fw-bolder text-hover-primary mb-1 fs-6'
                                  >
                                    {job.title}{' '}
                                  </a>
                                  {job.isRemote && (
                                    <span className='badge badge-primary'>Remoto</span>
                                  )}
                                </p>
                              </td>
                              <td
                                className='text-muted fw-bold'
                                style={{fontSize: 'smaller', whiteSpace: 'nowrap'}}
                              >
                                <span className='text-muted fw-bold d-block'>
                                  {' '}
                                  <i className='fas fa-building'></i> {job?.company?.name}
                                </span>
                              </td>
                              <td
                                className='text-muted fw-bold'
                                style={{fontSize: 'smaller', whiteSpace: 'nowrap'}}
                              >
                                <span className='text-muted fw-bold d-block'>
                                  {' '}
                                  <i className='fas fa-map-marked-alt'></i> {job?.location?.name}
                                </span>
                              </td>
                              <td className='text-muted fw-bold'>
                                <OverlayTrigger
                                  delay={{hide: 450, show: 300}}
                                  overlay={(props) => (
                                    <Tooltip {...props}>{job.created_at.toLocaleString()}</Tooltip>
                                  )}
                                  placement='bottom'
                                >
                                  <span className='badge badge-light-primary'>
                                    <TimeAgo time={job.created_at} />
                                  </span>
                                </OverlayTrigger>
                                {/* {job.created_at.toLocaleDateString()} */}
                              </td>
                              <td>
                                <span className='badge badge-light-success'>
                                  {job.hyreType.name}
                                </span>
                              </td>
                              <td className='text-end'>
                                <OverlayTrigger
                                  delay={{hide: 450, show: 300}}
                                  overlay={(props) => <Tooltip {...props}>Visualizar</Tooltip>}
                                  placement='bottom'
                                >
                                  <a
                                    onClick={() => {
                                      navigate(`/ofertas-de-empleo/${job._id}`)
                                    }}
                                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-info me-2'
                                  >
                                    <i className='fas fa-eye'></i>
                                  </a>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  delay={{hide: 450, show: 300}}
                                  overlay={(props) => (
                                    <Tooltip {...props}>
                                      {job.isActive ? 'Inactivar' : 'Activar'}
                                    </Tooltip>
                                  )}
                                  placement='bottom'
                                >
                                  <a
                                    onClick={() => {
                                      toggleStateJob(job)
                                    }}
                                    className={`btn btn-sm btn-icon btn-bg-light ${
                                      !job.isActive
                                        ? 'btn-active-color-danger'
                                        : 'btn-active-color-success'
                                    } me-2`}
                                  >
                                    {job.isActive ? (
                                      <i className='fas fa-check-square'></i>
                                    ) : (
                                      <i className='fas fa-minus-square'></i>
                                    )}
                                  </a>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  delay={{hide: 450, show: 300}}
                                  overlay={(props) => <Tooltip {...props}>Editar</Tooltip>}
                                  placement='bottom'
                                >
                                  <a
                                    onClick={() => {
                                      let model = {
                                        _id: job._id,
                                        companyType: 'existing',
                                        companyId: job.company._id.toString(),
                                        companyName: '',
                                        companyUrl: '',
                                        companyEmail: '',
                                        companyLogoUrl: '',
                                        title: job.title,
                                        description: job.description,
                                        isRemote: job.isRemote,
                                        category: job.category._id.toString(),
                                        hyreType: job.hyreType._id.toString(),
                                        location: job.location._id.toString(),
                                        salary: job.salary ? job.salary : 0,
                                        phone: job.phone ? job.phone : '',
                                        linkToApply: job.linkToApply ? job.linkToApply : '',
                                        emails: job.emails?.join(', '),
                                        contactMethod: job.contactMethod
                                          ? job.contactMethod[0]
                                          : 'EMAIL',
                                        onlyImage: job.onlyImage,
                                        image: job.image,
                                      }
                                      setEditModel(model)
                                      setIsModalEditVisible(true)
                                    }}
                                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                                  >
                                    <i className='fas fa-pen'></i>
                                  </a>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                      {/* end::Table body */}
                    </table>
                    <Pagination
                      pageSize={pageSize}
                      totalRecorsds={totalRecords}
                      currentPage={currentPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                )}
                {/* end::Table */}
              </div>
            </div>
          </div>
          {/* end::Body */}
        </div>
      </div>
      <CreateJob
        isModalVisible={isModalCreateVisible}
        onCreateJob={onEditLocations}
        setIsModalVisible={setIsModalCreateVisible}
      />
      <EditJob
        isModalVisible={isModalEditVisible}
        onCreateLocation={onEditLocations}
        setIsModalVisible={setIsModalEditVisible}
        initFormValues={editModel}
      />
    </>
  )
}
