/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useRef, useState} from 'react'
import {Form, Formik, FormikValues} from 'formik'
import {Modal} from 'react-bootstrap'
import {StepperComponent} from '_metronic/assets/ts/components'
import {createJobSchemas, ICreateJob} from './CreateJobWizardHelper'
import {JobStep1} from './steps/JobStep1'
import {JobStep2} from './steps/JobStep2'
import {JobStep3} from './steps/JobStep3'
import {KTSVG} from '_metronic/helpers'
import {UserModel} from 'app/modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from 'setup'
import {
  CompanyControllerServiceProxy,
  CreateCompanyDto,
  JobControllerServiceProxy,
  UpdateJobDto,
} from 'app/services/service-proxies/service-proxies'
import axios from 'axios'
import {message} from 'antd'
import {JobsStatisticsContext} from '../ProfilePage'

type Props = {
  isModalVisible: boolean
  setIsModalVisible: (isModalVisible: boolean) => void
  onCreateLocation: () => void
  initFormValues: ICreateJob
}

export const EditJob: FC<Props> = ({
  isModalVisible,
  setIsModalVisible,
  onCreateLocation,
  initFormValues,
}) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createJobSchemas[0])
  //   const [initFormValues] = useState<ICreateJob>(inits)
  const [companies, setCompanies] = useState<any[]>([])
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const jobClient = new JobControllerServiceProxy(undefined, axios)
  const categoryClient = new CompanyControllerServiceProxy(undefined, axios)
  const context = useContext(JobsStatisticsContext)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createJobSchemas[stepper.current.currentStepIndex - 1])
  }

  const submitStep = (values: ICreateJob, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    setCurrentSchema(createJobSchemas[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      let model: UpdateJobDto = new UpdateJobDto()
      model._id = values._id!
      model.title = values.title
      model.description = values.description
      model.location = values.location
      model.category = values.category
      model.salary = parseInt(values.salary!)
      model.hyreType = values.hyreType
      model.isRemote = values.isRemote
      model.isActive = true
      model.companyType = values.companyType === 'new' ? true : false
      model.emails = values.emails ? values.emails.split(',').map((element) => element.trim()) : []
      model.onlyImage = values.onlyImage ? true : false
      model.phone = values.phone?.trim() ? values.phone?.trim() : ''
      model.linkToApply = values.linkToApply?.trim() ? values.linkToApply?.trim() : ''
      model.contactMethod = values.contactMethod
      model.image = model.onlyImage ? values.image : ''
      model.description = model.onlyImage ? '' : values.description

      if (values.companyType === 'new') {
        let company: CreateCompanyDto = new CreateCompanyDto()
        company.name = values.companyName
        company.email = values.companyEmail
        company.url = values.companyUrl
        company.logoUrl = values.companyLogoUrl
        model.company = company
      } else {
        model.companyId = values.companyId
      }

      jobClient
        .update(model._id, model)
        .then(() => {
          setIsModalVisible(false)
          actions.resetForm()
          message.success('Vacante editada con éxito')
          onCreateLocation()
          jobClient.getJobsStatistics().then((res) => {
            context.setJobStatistics(res.data)
          })
        })
        .catch((err: any) => {
          if (err.response) actions.setStatus(err.response.result.error.message)
          else actions.setStatus(err.message)
        })
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    categoryClient.getAllWithoutPagination(user._id).then((res) => {
      setCompanies(res.data)
    })

    loadStepper()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepperRef, isModalVisible])

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <div>
      <Modal show={isModalVisible} fullscreen={true} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Vacante</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='card'>
            <div className='card-body'>
              <div
                ref={stepperRef}
                className='stepper stepper-links d-flex flex-column pt-15'
                id='edit job stepper'
              >
                <div className='stepper-nav mb-5'>
                  <div className='stepper-item current' data-kt-stepper-element='nav'>
                    <h3 className='stepper-title'>Compañia</h3>
                  </div>

                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    <h3 className='stepper-title'>Informacion de la vacante</h3>
                  </div>

                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    <h3 className='stepper-title'>Otros Datos</h3>
                  </div>

                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    <h3 className='stepper-title'>Completar</h3>
                  </div>
                </div>

                <Formik
                  validationSchema={currentSchema}
                  initialValues={initFormValues}
                  onSubmit={submitStep}
                >
                  {({status}) => {
                    return (
                      <Form
                        className='mx-auto w-100 pt-15 pb-10'
                        noValidate
                        id='kt_create_account_form'
                      >
                        <div className='current' data-kt-stepper-element='content'>
                          <JobStep1 companies={companies.filter((x) => x.isActive)} />
                        </div>

                        <div data-kt-stepper-element='content'>
                          <JobStep2 />
                        </div>

                        <div data-kt-stepper-element='content'>
                          <JobStep3 companies={companies.filter((x) => x.isActive)} />
                        </div>

                        <div data-kt-stepper-element='content'>
                          <div className='w-100'>
                            <div className='pb-8 pb-lg-10'>
                              <h2 className='fw-bolder text-dark'>¡Terminaste!</h2>
                            </div>

                            <div className='mb-0'>
                              <div className='fs-6 text-gray-600 mb-5'>
                                <h3>Antes de terminar debes tomar en cuenta:</h3>
                                <ol className='list-group list-group-numbered'>
                                  <li className='list-group-item'>
                                    La vacante será verificada antes de ser publicada, en todo caso
                                    de cometer un error será contactado via correo.
                                  </li>
                                  <li className='list-group-item'>
                                    La publicación no estará visible hasta realizar el pago del
                                    servicio.
                                  </li>
                                  <li className='list-group-item'>
                                    Las vacantes publicadas en esta plataforma serán difundidas en
                                    nuestras redes sociales (Instagram, Facebook, Linkedin, Otros).
                                  </li>
                                </ol>
                              </div>
                              <div className='fs-6 text-gray-600 mb-5'>
                                <h2 className='fw-bolder text-dark'>
                                  Muchas gracias por crear tu vacante, esperamos que tengas suerte
                                  en tu busqueda.
                                </h2>
                              </div>

                              {status && (
                                <div className='notice d-flex bg-light-danger rounded border-danger border border-dashed p-6'>
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen044.svg'
                                    className='svg-icon-2tx svg-icon-danger me-4'
                                  />
                                  <div className='d-flex flex-stack flex-grow-1'>
                                    <div className='fw-bold'>
                                      <h4 className='text-gray-800 fw-bolder'>
                                        ¡Necesitamos su atención!
                                      </h4>
                                      {Array.isArray(status) ? (
                                        status?.map((error: string) => {
                                          return <div className='fs-6 text-gray-600'>{error}</div>
                                        })
                                      ) : (
                                        <div className='fs-6 text-gray-600'>{status}</div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className='d-flex flex-stack pt-15'>
                          <div className='mr-2'>
                            <button
                              onClick={prevStep}
                              type='button'
                              className='btn btn-lg btn-light-primary me-3'
                              data-kt-stepper-action='previous'
                            >
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr063.svg'
                                className='svg-icon-4 me-1'
                              />
                              Atras
                            </button>
                          </div>

                          <div>
                            <button type='submit' className='btn btn-lg btn-primary me-3'>
                              <span className='indicator-label'>
                                {stepper.current?.currentStepIndex !==
                                  stepper.current?.totatStepsNumber! - 1 && 'Continuar'}
                                {stepper.current?.currentStepIndex ===
                                  stepper.current?.totatStepsNumber! - 1 && 'Guardar'}
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr064.svg'
                                  className='svg-icon-3 ms-2 me-0'
                                />
                              </span>
                            </button>
                          </div>
                        </div>
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
