import {toAbsoluteUrl} from '_metronic/helpers'
import React from 'react'

const Banner = ({
  children,
  img,
  btnText,
}: {
  children: React.ReactNode
  img?: string
  btnText?: string
}) => {
  return (
    <>
      {/* <div className='col-12'>
        <div
          className='card border-transparent'
          data-bs-theme='light'
          style={{backgroundColor: '#1C325E'}}
        >
          <div className='card-body d-block d-lg-flex ps-xl-15'>
            <div className='m-0'>
              {children}
              <div className='mb-3'>
                <a
                  href='https://api.whatsapp.com/send?phone=18494997714&text=%F0%9F%98%81%20%C2%A1Hola!%20Estoy%20interesado%20en%20promocionar%20mi%20negocio%20en%20su%20p%C3%A1gina%20web.%20%C2%BFPodr%C3%ADan%20brindarme%20m%C3%A1s%20informaci%C3%B3n%2C%20por%20favor%3F'
                  target='_blank'
                  className='btn btn-danger fw-semibold me-2'
                  rel='noreferrer'
                >
                  {btnText ? btnText : '¡Anúnciate hoy!'}
                </a>
              </div>
            </div>
            <img
              src={
                img ? toAbsoluteUrl(img) : toAbsoluteUrl('/media/illustrations/sigma-1/17-dark.png')
              }
              className='position-absolute me-3 bottom-0 end-0 h-200px'
              alt=''
            />
          </div>
        </div>
      </div> */}
      <div className='col-12'>
        <div
          className='card border-transparent'
          data-bs-theme='light'
          style={{backgroundColor: '#1C325E'}}
        >
          <div className='card-body d-flex flex-column flex-lg-row align-items-start ps-xl-15'>
            <div className='m-0'>
              {children}
              <div className='mb-3'>
                <a
                  href='https://api.whatsapp.com/send?phone=18494997714&text=%F0%9F%98%81%20%C2%A1Hola!%20Estoy%20interesado%20en%20promocionar%20mi%20negocio%20en%20su%20p%C3%A1gina%20web.%20%C2%BFPodr%C3%ADan%20brindarme%20m%C3%A1s%20informaci%C3%B3n%2C%20por%20favor%3F'
                  target='_blank'
                  className='btn btn-danger fw-semibold me-2 w-100 w-lg-auto pulse'
                  rel='noreferrer'
                >
                  {btnText ? btnText : '¡Anúnciate hoy!'}
                  <span className='pulse-ring'></span>
                </a>
              </div>
            </div>
            <div>
              <img
                src={
                  img
                    ? toAbsoluteUrl(img)
                    : toAbsoluteUrl('/media/illustrations/sigma-1/17-dark.png')
                }
                className='position-absolute me-3 bottom-0 end-0 h-200px d-none d-lg-block'
                alt=''
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Banner
