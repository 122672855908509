import React, {FC} from 'react'

type Props = {
  time: any
}

export const TimeAgo: FC<Props> = ({time}) => {
  const time_ago = (time: any) => {
    switch (typeof time) {
      case 'number':
        break
      case 'string':
        time = +new Date(time)
        break
      case 'object':
        if (time.constructor === Date) time = time.getTime()
        break
      default:
        time = +new Date()
    }
    var time_formats = [
      [60, 'segundos', 1], // 60
      [120, 'hace 1 minuto', '1 minuto a partir de ahora'], // 60*2
      [3600, 'minutos', 60], // 60*60, 60
      [7200, 'hace 1 hora', '1 hora a partir de ahora'], // 60*60*2
      [86400, 'horas', 3600], // 60*60*24, 60*60
      [172800, 'ayer', 'mañana'], // 60*60*24*2
      [604800, 'dias', 86400], // 60*60*24*7, 60*60*24
      [1209600, 'la semana pasada', 'la próxima semana'], // 60*60*24*7*4*2
      [2419200, 'semanas', 604800], // 60*60*24*7*4, 60*60*24*7
      [4838400, 'El mes pasado', 'Próximo mes'], // 60*60*24*7*4*2
      [29030400, 'meses', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
      [58060800, 'El año pasado', 'El próximo año'], // 60*60*24*7*4*12*2
      [2903040000, 'años', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
      [5806080000, 'siglo pasado', 'Próximo siglo'], // 60*60*24*7*4*12*100*2
      [58060800000, 'siglos', 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ]
    var seconds = (+new Date() - time) / 1000,
      token = 'atrás',
      list_choice = 1

    if (seconds === 0) {
      return 'Justo ahora'
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds)
      token = 'desde ahora'
      list_choice = 2
    }
    var i = 0,
      format
    while ((format = time_formats[i++]))
      if (seconds < format[0]) {
        if (typeof format[2] == 'string') return format[list_choice]
        else return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token
      }
    return time
  }
  return <>{time_ago(time)}</>
}
