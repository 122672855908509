/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { Registration } from './components/Registration'
import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { ResetPassword } from './components/ResetPassword'
import { AcceptTerms } from './components/AcceptTerms'
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'

const AuthLayout = () => {
  const location = useLocation()

  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      }}
    >
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 position-relative'>
        <a href='/' style={{ top: '5rem', left: '50', position: 'absolute' }}>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/TU-EMPLEO-IDEAL-V2-Horizontal.png')}
            style={{ filter: 'brightness(0) invert(1)' }}
          />
        </a>
        <div
          className={clsx('bg-white rounded shadow-lg p-10 p-lg-15 mx-auto', {
            'w-lg-1000px': location.pathname.includes('registration'),
            'w-lg-500px': !location.pathname.includes('registration'),
          })}
          style={{ top: '13rem', left: '50', position: 'absolute' }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset-password/:token' element={<ResetPassword />} />
      <Route path='terms' element={<AcceptTerms />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export { AuthPage }
