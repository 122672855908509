import {FaqsControllerServiceProxy} from 'app/services/service-proxies/service-proxies'
import axios from 'axios'
import React from 'react'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Modal} from 'antd'

export const FaqRow: React.FC<{
  id: string
  question: string
  answer: string
  showDivider: boolean
  showOptions: boolean
  onDelete: () => void
  onEdit: () => void
}> = ({id, question, answer, showDivider = false, showOptions = false, onDelete, onEdit}) => {
  const faqsClient = new FaqsControllerServiceProxy(undefined, axios)
  const {confirm} = Modal
  const handleDelete = () => {
    confirm({
      title: '¿Quieres eliminar esta pregunta?',
      icon: <ExclamationCircleOutlined />,
      content: 'Una vez eliminada la pregunta no podrá ser recuperada',
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        faqsClient.remove(id).then(() => {
          onDelete()
        })
      },
    })
  }
  return (
    <>
      <div className='m-0'>
        <div className='d-flex align-items-center collapsible py-3 toggle collapsed mb-0 justify-content-between'>
          <div className='d-flex' data-bs-toggle='collapse' data-bs-target={`#question_row_${id}`}>
            <div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-5'>
              <span className='svg-icon toggle-on svg-icon-primary svg-icon-1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width={24}
                  height={24}
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <rect opacity='0.3' x={2} y={2} width={20} height={20} rx={5} fill='black' />
                  <rect x='6.0104' y='10.9247' width={12} height={2} rx={1} fill='black' />
                </svg>
              </span>
              <span className='svg-icon toggle-off svg-icon-1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width={24}
                  height={24}
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <rect opacity='0.3' x={2} y={2} width={20} height={20} rx={5} fill='black' />
                  <rect
                    x='10.8891'
                    y='17.8033'
                    width={12}
                    height={2}
                    rx={1}
                    transform='rotate(-90 10.8891 17.8033)'
                    fill='black'
                  />
                  <rect x='6.01041' y='10.9247' width={12} height={2} rx={1} fill='black' />
                </svg>
              </span>
            </div>
            <h4 className='text-gray-700 fw-bolder cursor-pointer mb-0 mt-3'>{question} ?</h4>
          </div>
          {showOptions && (
            <div>
              <button type='button' className='btn btn-icon btn-danger me-2' onClick={handleDelete}>
                <i className='fa fa-times' aria-hidden='true'></i>
              </button>
              <button className='btn btn-icon btn-primary' onClick={onEdit}>
                <i className='fas fa-edit'></i>
              </button>
            </div>
          )}
        </div>
        <div id={`question_row_${id}`} className='collapse fs-6 ms-1'>
          <div className='mb-4 text-gray-600 fw-bold fs-6 ps-10'>
            <div dangerouslySetInnerHTML={{__html: answer}} />
          </div>
        </div>
        {showDivider && <div className='separator separator-dashed' />}
      </div>
    </>
  )
}
