import React, {FC, useState} from 'react'
import axios from 'axios'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {Modal, Button} from 'antd'
import clsx from 'clsx'
import {
  CategoriesControllerServiceProxy,
  CreateCategoryDto,
} from 'app/services/service-proxies/service-proxies'

const categoryClient = new CategoriesControllerServiceProxy(undefined, axios)

type Props = {
  isModalVisible: boolean
  setIsModalVisible: (isModalVisible: boolean) => void
  onCreateCategory: () => void
}

const initialValues = {
  name: '',
}

const registrationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimo 3 caracteres')
    .max(50, 'Maximo 50 caracteres')
    .required('El primer nombre es requerido'),
})

export const CreateCategory: FC<Props> = ({
  isModalVisible,
  setIsModalVisible,
  onCreateCategory,
}) => {
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      let data: CreateCategoryDto = new CreateCategoryDto()
      data.init(values)
      data.name = values.name.toUpperCase()
      categoryClient
        .create(data)
        .then(() => {
          setLoading(false)
          setIsModalVisible(false)
          onCreateCategory()
        })
        .catch((err: any) => {
          setLoading(false)
          setSubmitting(false)
          setStatus(err.message)
        })
    },
  })

  return (
    <Modal
      title='Crear categoria'
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      okText='Editar'
      cancelText='Cancelar'
      destroyOnClose={true}
      footer={[
        <Button key='back' onClick={() => setIsModalVisible(false)}>
          Cancelar
        </Button>,
        <Button key='submit' type='primary' loading={loading} onClick={() => formik.handleSubmit()}>
          Guardar
        </Button>,
      ]}
    >
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
      >
        {/* begin::Form group name */}
        <div className='fv-row mb-7'>
          <label className='class="form-label fw-bolder text-dark fs-6 required'>Nombre</label>
          <input
            placeholder='Nombre'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('name')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.name && formik.errors.name,
              },
              {
                'is-valid': formik.touched.name && !formik.errors.name,
              }
            )}
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.name}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
      </form>
    </Modal>
  )
}
