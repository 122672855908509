/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import axios from 'axios'
import {useIntl} from 'react-intl'
import {AutoComplete} from 'antd'
import {
  CategoriesControllerServiceProxy,
  GetCategoryDto,
  GetLocationDto,
  JobControllerServiceProxy,
  LocationsControllerServiceProxy,
  LinkControllerServiceProxy,
} from 'app/services/service-proxies/service-proxies'
import './DashboardWrapper.css'
import {PageTitle} from '../../../_metronic/layout/core'
import {CardJob} from '../jobs/CardJob'
import {StatisticsWidget2, StatisticsWidget5} from '_metronic/partials/widgets'
import {useNavigate} from 'react-router-dom'
import {IFilters} from '../jobs/JobPage'
import {useFormik} from 'formik'
import {Helmet} from 'react-helmet'
import {Panel} from 'app/shared/components/Panel'
import {LinkType} from 'app/shared/constants/linkTypes'
import {VideoSlider} from 'app/shared/components/VideoSlider'
import {removeNullUndefined} from 'app/shared/utils/utils'
import Banner from 'app/shared/components/Banner'

type Props = {
  categories: GetCategoryDto[]
  locations: GetLocationDto[]
  links: any[]
  jobs: any[]
  statistics: any
}

const DashboardPage: FC<Props> = ({categories, locations, jobs, statistics, links}) => {
  const navigate = useNavigate()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filters, setFilters] = React.useState<IFilters>({})
  const [filterValues, setFilterValues] = React.useState<IFilters>({})

  const initialValues: IFilters = {
    keyword: '',
    location: '',
    hyreType: '',
    category: '',
    postedBy: '',
    company: '',
    plublishedDate: undefined,
    isActive: undefined,
    isRemote: undefined,
  }

  const form = useFormik({
    initialValues,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setFilters(values)
      const payload = removeNullUndefined(values)
      let query = new URLSearchParams(payload).toString()
      const url = `/ofertas-de-empleo/search?${query}`
      navigate(url)
    },
  })

  return (
    <>
      <Helmet>
        <meta
          name='description'
          content='Encuentra los mejores empleos en Republica Dominicana. 
        Explora nuestras ofertas de trabajo y aplica hoy mismo. Consejos para entrevistas y redacción de currículos.'
        />
        <title>Tu Empleo Ideal | Empleos en Rep. Dom. - Encuentra Trabajo</title>
      </Helmet>
      <div className='container d-flex flex-column' style={{top: '150px', left: '50'}}>
        <div className='d-flex align-items-sm-end flex-column flex-sm-row mb-5'>
          <h2 className='d-flex align-items-center text-white mr-5 mb-0'>Buscar una vacante</h2>
        </div>
        <div className='d-flex align-items-md-center mb-2 flex-column flex-md-row'>
          <div
            className='bg-white rounded shadow p-4 d-flex flex-grow-1 flex-sm-grow-0 mb-20 mt-10'
            style={{width: '100%'}}
          >
            <form
              id='searchHomeForm'
              style={{display: 'flex', width: '100%'}}
              className='searchHomeForm'
              onSubmit={form.handleSubmit}
            >
              <input type='submit' hidden />
              <div
                className='d-flex align-items-center py-3 py-sm-0 px-sm-3'
                style={{width: '30%'}}
              >
                <span className='svg-icon svg-icon-lg'>
                  <svg
                    className='svg-icon search-icon'
                    aria-labelledby='title desc'
                    role='img'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 19.9 19.7'
                  >
                    <title id='title'>Search Icon</title>
                    <desc id='desc'>A magnifying glass icon.</desc>
                    <g className='search-path' fill='none' stroke='#848F91'>
                      <path strokeLinecap='square' d='M18.5 18.3l-5.4-5.4' />
                      <circle cx='8' cy='8' r='7' />
                    </g>
                  </svg>
                </span>
                <input
                  type='text'
                  className='form-control border-0 font-weight-bold pl-2'
                  {...form.getFieldProps('keyword')}
                  placeholder='Posición, descripcion, etc.'
                />
              </div>
              <span className='bullet bullet-vertical bg-secondary h-auto my-1 d-none d-sm-flex mr-2'></span>
              <div
                className='d-flex align-items-center py-3 py-sm-0 px-sm-3'
                style={{width: '30%'}}
              >
                <AutoComplete
                  value={filterValues.category}
                  onChange={(e) => {
                    setFilterValues({...filterValues, category: e})
                  }}
                  onSelect={(value: any, option: any) => {
                    setFilterValues({...filterValues, category: option.label})
                    form.setFieldValue('category', value)
                  }}
                  allowClear={true}
                  dropdownClassName='border-0'
                  style={{width: '100%'}}
                  options={categories.map((option: GetCategoryDto) => {
                    return {
                      value: option._id,
                      label: option.name,
                    }
                  })}
                  filterOption={(inputValue, option) =>
                    option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                >
                  <input
                    type='text'
                    className='form-control border-0 font-weight-bold pl-2'
                    placeholder='Categoría'
                  />
                </AutoComplete>
              </div>
              <span className='bullet bullet-vertical bg-secondary h-auto my-1 d-none d-sm-flex mr-2'></span>
              <div
                className='d-flex align-items-center py-3 py-sm-0 px-sm-3'
                style={{width: '30%'}}
              >
                <AutoComplete
                  value={filterValues.location}
                  onChange={(e) => {
                    setFilterValues({...filterValues, location: e})
                  }}
                  onSelect={(value: any, option: any) => {
                    setFilterValues({...filterValues, location: option.label})
                    form.setFieldValue('location', value)
                  }}
                  allowClear={true}
                  style={{width: '100%'}}
                  options={locations?.map((option: GetLocationDto) => {
                    return {
                      value: option._id,
                      label: option.name,
                    }
                  })}
                  filterOption={(inputValue, option) =>
                    option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                >
                  <input
                    type='text'
                    className='form-control border-0 font-weight-bold pl-2'
                    placeholder='Locación'
                  />
                </AutoComplete>
              </div>
              <button
                type='submit'
                className='btn btn-dark font-weight-bold btn-hover-light-primary mt-3 mt-sm-0 px-7'
              >
                Buscar
              </button>
            </form>
          </div>
        </div>
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-4'>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8 shadow'
              svgIcon='/media/icons/duotune/finance/fin006.svg'
              color='white'
              iconColor='info'
              title={statistics?.jobs}
              image='abstract-4.svg'
              description='Vacantes disponibles'
            />
          </div>

          <div className='col-xl-4 d-none d-md-block'>
            <StatisticsWidget5
              image='abstract-2.svg'
              className='card-xl-stretch mb-xl-8 shadow'
              svgIcon='/media/icons/duotune/communication/com014.svg'
              color='white'
              iconColor='success'
              title={statistics?.users}
              description='Miembros'
            />
          </div>

          <div className='col-xl-4 d-none d-md-block'>
            <StatisticsWidget5
              image='abstract-1.svg'
              className='card-xl-stretch mb-xl-8 shadow'
              svgIcon='/media/icons/duotune/general/gen001.svg'
              color='white'
              iconColor='warning'
              title={statistics?.companies}
              description='Compañías'
            />
          </div>
        </div>
        {/* <div className='row g-5 g-xl-8 mt-2 mt-md-0'>
          <div className='col-xl-4'>
            <StatisticsWidget2
              className='card-xl-stretch mb-xl-8 shadow'
              avatar='/media/svg/avatars/029-boy-11.svg'
              title=''
              description='Recuerda aplicar a las vacantes que se ajustan a tu perfil para que tengas una búsqueda efectiva.'
            />
          </div>

          <div className='col-xl-4'>
            <StatisticsWidget2
              className='card-xl-stretch mb-xl-8 shadow'
              avatar='/media/svg/avatars/014-girl-7.svg'
              title=''
              description='Tu curriculum vitae será enviado directamente a la empresa que apliques.'
            />
          </div>

          <div className='col-xl-4'>
            <StatisticsWidget2
              className='card-xl-stretch mb-5 mb-xl-8 shadow'
              avatar='/media/svg/avatars/004-boy-1.svg'
              title=''
              description='Cualquier duda que tengas puedes escribirnos a nuestro formulario de contacto al final de la pagina.'
            />
          </div>
        </div> */}
        <div className='row gap-10'>
          <div className='col-12 mt-5'>
            <Panel title='Consejos para la Búsqueda de Empleo'>
              <div className='row'>
                <VideoSlider playlist={links} />
              </div>
            </Panel>
          </div>
          <div className='col-12'>
            <Banner>
              <div className='position-relative fs-2x z-index-2 fw-bold text-white mb-7'>
                <span className='me-2'>
                  Promociona tu negocio frente a nuestros mas de
                  <span className='position-relative d-inline-block text-danger ms-2'>
                    <a
                      href='https://linktr.ee/tu_empleo_ideal'
                      target='_blank'
                      className='text-danger opacity-75-hover'
                      rel='noreferrer'
                    >
                      140,000
                    </a>
                    {/*begin::Separator*/}
                    <span className='position-absolute opacity-50 bottom-0 start-0 border-4 border-danger border-bottom w-100' />
                    {/*end::Separator*/}
                  </span>
                </span>
                seguidores.
                <br />
                ¡Contáctanos hoy y haz crecer tu marca!
              </div>
            </Banner>
          </div>
        </div>
        <div className='mb-19 my-20 recents-job-list'>
          <div className='text-center mb-12'>
            <h2>Últimas Ofertas de Trabajo</h2>
          </div>
          <div className='row g-10'>
            <div className='col'>
              {jobs.map((job: any) => (
                <CardJob key={job._id} job={job} />
              ))}
              <div className='d-grid gap-2'>
                <button
                  onClick={() => navigate('/ofertas-de-empleo')}
                  className='btn btn-outline btn-outline-dashed btn-outline-primary'
                  type='button'
                >
                  Ver más
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const [categories, setCategories] = React.useState([])
  const [locations, setLocation] = React.useState([])
  const [recentJobs, setRecentJobs] = React.useState([])
  const [statistics, setStatistics] = React.useState<any>({})
  const [links, setLinks] = React.useState<any>([])

  const jobClient = new JobControllerServiceProxy(undefined, axios)
  const categoryClient = new CategoriesControllerServiceProxy(undefined, axios)
  const locationClient = new LocationsControllerServiceProxy(undefined, axios)
  const linkClient = new LinkControllerServiceProxy(undefined, axios)

  const getData = async () => {
    const [locations, categories, jobs, statistics, links] = await Promise.all([
      locationClient.getAllWithoutPagination(),
      categoryClient.getAllWithoutPagination(),
      jobClient.getRecentJobs(10),
      jobClient.getStatistics(),
      linkClient.getAllWithoutPagination(LinkType.Youtube),
    ])

    setLocation(locations.data || [])
    setCategories(categories.data || [])
    setRecentJobs(jobs.data || [])
    setStatistics(statistics.data || [])
    setLinks(links.data || [])
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage
        categories={categories}
        locations={locations}
        jobs={recentJobs}
        statistics={statistics}
        links={links}
      />
    </div>
  )
}

export {DashboardWrapper}
