import React from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { LinkPage } from './LinkPage';


const LinkLayout: React.FC = () => {
    return (
        <div className='content flex-row-fluid'>
            <div className='row'>
                <Outlet />
            </div>
        </div>
    );
}

export const LinkContext = React.createContext<any>(null!)

export interface ILink {
    name: string, url: string, icon: string, linkType: number, createdAt: Date, isActive: boolean,
}

export const LinkWrapper = () => {
    const [link, setLink] = React.useState<ILink>({
        name: '',
        url: '',
        icon: '',
        linkType: 0,
        createdAt: new Date(),
        isActive: true
    })

    return (
        <LinkContext.Provider value={{ link, setLink }}>
            <Routes>
                <Route element={<LinkLayout />}>
                    <Route path='/' element={<LinkPage />} />
                </Route>
            </Routes>
        </LinkContext.Provider>
    )
}