import React from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { BlogDetailPage } from './BlogDetailPage';
import { BlogPage } from './BlogPage';
import { CreateBlogPage } from './CreateBlogPage';
import { isMobileDevice } from '_metronic/assets/ts/_utils/DomHelpers'


const BlogLayout: React.FC = () => {
    React.useEffect(() => {
        if (!isMobileDevice()) {
            document.getElementById('kt_post')?.classList.remove('container')
            document.getElementById('kt_post')?.classList.add('w-100')
            return () => {
                document.getElementById('kt_post')?.classList.remove('w-100')
                document.getElementById('kt_post')?.classList.add('container')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className='content flex-row-fluid'>
            <div className='row'>
                <Outlet />
            </div>
        </div>
    );
}

export const BlogContext = React.createContext<any>(null!)

export interface IBlog {
    title: string, createdAt: Date, description: string, views: number, mainImage: string
}

export const BlogWrapper = () => {
    const [blog, setBlog] = React.useState<IBlog>({
        title: '',
        createdAt: new Date(),
        description: '',
        views: 0,
        mainImage: ''
    })

    return (
        <BlogContext.Provider value={{ blog, setBlog }}>
            <Routes>
                <Route element={<BlogLayout />}>
                    <Route path='/' element={<BlogPage />} />
                    <Route path='/create' element={<CreateBlogPage />} />
                    <Route path='/create/:slug' element={<CreateBlogPage />} />
                    <Route path='/detail/:slug' element={<BlogDetailPage />} />
                </Route>
            </Routes>
        </BlogContext.Provider>
    )
}