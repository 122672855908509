/* eslint-disable jsx-a11y/anchor-is-valid */
import {message} from 'antd'
import {EmailInput, MailControllerServiceProxy} from 'app/services/service-proxies/service-proxies'
import axios from 'axios'
import {useFormik} from 'formik'
import {FC} from 'react'
import * as Yup from 'yup'

const initialValues = {
  name: '',
  email: '',
  phone: '',
  subject: '',
  message: '',
}

const registrationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(3, 'Minimo 3 caracteres')
    .max(50, 'Maximo 50 caracteres')
    .required('El nombre es requerido'),
  email: Yup.string().email('El correo es invalido').required('El correo es requerido'),
  phone: Yup.string().min(10, 'Minimo 10 caracteres').max(12, 'Maximo 12 caracteres'),
  subject: Yup.string()
    .trim()
    .min(3, 'Minimo 3 caracteres')
    .max(50, 'Maximo 50 caracteres')
    .required('El asunto es requerido'),
  message: Yup.string()
    .trim()
    .min(3, 'Minimo 3 caracteres')
    .max(1000, 'Maximo 50 caracteres')
    .required('El mensaje es requerido'),
})
const SubscriptionSchema = Yup.object().shape({
  email: Yup.string().email('El correo es invalido').required('El correo es requerido'),
})

const Footer: FC = () => {
  const mailClient = new MailControllerServiceProxy(undefined, axios)

  const sendEmail = async (values: any) => {
    const payload = new EmailInput()
    payload.init({
      name: values.name,
      email: values.email,
      subject: `${values.subject} | Mensaje enviado desde la pagina web de tu empleo ideal`,
      body: `
        ${values.message}, 
        Mis contactos son: Email: ${values.email}, Telefono: ${values.phone}
        `,
      attachments: '',
    })
    mailClient
      .send(payload)
      .then(() => {
        message.success('Su mensaje ha sido enviado')
        formik.resetForm()
      })
      .catch(() => {
        message.error('Ha ocurrido un error al enviar su mensaje')
      })
  }

  const sendEmailSubscription = async (values: any) => {
    mailClient.subscribeEmail(values.email).then(() => {
      message.success('Su correo ha sido registrado')
      formSubscription.resetForm()
    })
  }

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      sendEmail(values)
    },
  })
  const formSubscription = useFormik({
    initialValues: {email: ''},
    validationSchema: SubscriptionSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      sendEmailSubscription(values)
    },
  })

  return (
    <>
      <footer className='text-center text-lg-start'>
        <div className='container'>
          <section className='pt-10'>
            <form onSubmit={formSubscription.handleSubmit}>
              <div className='row d-flex justify-content-center'>
                <div className='col-auto'>
                  <p className='pt-2 text-white'>
                    <strong>Suscríbete a nuestro boletín de vacantes</strong>
                  </p>
                </div>
                <div className='col-md-7 col-12'>
                  <div className='form-outline mb-4'>
                    <input
                      type='email'
                      className='form-control'
                      placeholder='Correo electronico'
                      {...formSubscription.getFieldProps('email')}
                    />
                    {formSubscription.touched.email && formSubscription.errors.email ? (
                      <div>{formSubscription.errors.email}</div>
                    ) : null}
                  </div>
                </div>
                <div className='col-auto'>
                  <button type='submit' className='btn btn-primary mb-4'>
                    Suscríbete
                  </button>
                </div>
              </div>
            </form>
          </section>

          <section className='pb-150 pt-50'>
            <h1 className='text-light text-center'>Contacta con nosotros</h1>
            <p className='text-center'>
              Si tiene alguna duda o sugerencia, por favor escriba un mensaje a nuestro equipo de
              soporte.
            </p>
            <form className='form-inline justify-content-center' onSubmit={formik.handleSubmit}>
              <div className='form-group mx-sm-3 mb-2'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Nombre'
                  name='name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name ? <div>{formik.errors.name}</div> : null}
              </div>
              <div className='row mx-0 mx-lg-1 mb-2'>
                <div className='col-12 col-md-6 mb-2 mb-lg-0 px-0 px-lg-2'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Correo de contacto'
                    name='email'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div>{formik.errors.email}</div>
                  ) : null}
                </div>
                <div className='col-12 col-md-6 px-0 px-lg-2'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Telefono de contacto'
                    name='phone'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <div>{formik.errors.phone}</div>
                  ) : null}
                </div>
              </div>
              <div className='form-group mx-sm-3 mb-2'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Asunto'
                  name='subject'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.subject}
                />
                {formik.touched.subject && formik.errors.subject ? (
                  <div>{formik.errors.subject}</div>
                ) : null}
              </div>
              <div className='form-group mx-sm-3 mb-2'>
                <textarea
                  className='form-control'
                  placeholder='Mensaje'
                  name='message'
                  rows={6}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                />
                {formik.touched.message && formik.errors.message ? (
                  <div>{formik.errors.message}</div>
                ) : null}
              </div>
              <div className='d-grid'>
                <button
                  type='submit'
                  className='btn btn-primary mb-2 mt-5 ms-3'
                  disabled={formik.isSubmitting}
                >
                  Enviar
                </button>
              </div>
            </form>
          </section>
          {/* Section: Links  */}
          <section className='pb-120'>
            <div className='container text-center text-md-start mt-5'>
              <div className='row mt-3'>
                <div className='col-12 col-md-4 mx-auto mb-4'>
                  <h6 className='text-uppercase fw-bold mb-4'>
                    <i className='fas fa-gem me-3' />
                    Sobre nosotros
                  </h6>
                  <p>
                    Somos una empresa con más de 8 años de experiencia gestionando el talento humano
                    (asesoría, publicaciones y reclutamiento).
                  </p>
                </div>
                <div className='col-12 col-md-4 mx-auto mb-4'>
                  <h6 className='text-uppercase fw-bold mb-4'>SERVICIOS</h6>
                  <p className='text-start'>
                    <a href='#!' className='text-reset'>
                      Adquisición y Retención de Talentos
                    </a>
                  </p>
                  <p className='text-start'>
                    <a href='#!' className='text-reset'>
                      Sistema de Gestión por Competencias
                    </a>
                  </p>
                  <p className='text-start'>
                    <a href='#!' className='text-reset'>
                      Publicacion de vacantes
                    </a>
                  </p>
                  <p className='text-start'>
                    <a href='#!' className='text-reset'>
                      Eventos y ferias de empleo
                    </a>
                  </p>
                </div>
                <div className='col-12 col-md-4 mx-auto mb-md-0 mb-4'>
                  {/* Links */}
                  <h6 className='text-uppercase fw-bold mb-4'>CONTACTO</h6>
                  <p className='text-start'>
                    <i className='fas fa-envelope me-3' />
                    <a href='mailto:reclutamiento@tuempleoideal.com.do'>
                      reclutamiento@tuempleoideal.com.do
                    </a>
                  </p>
                  <p className='text-start'>
                    <i className='fas fa-envelope me-3' />
                    <a href='mailto:vacantestei@gmail.com'>vacantestei@gmail.com</a>
                  </p>
                  <p className='text-start'>
                    <i className='fas fa-phone me-3' /> 849-499-7714
                  </p>
                  <p className='text-start'>
                    <a
                      href='https://chat.whatsapp.com/L3kaOtg1X36D36Ddgntjt2'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <i className='fab fa-whatsapp me-3'></i> Grupo de Whatsapp
                    </a>
                  </p>
                  <p className='d-block d-md-none text-start text-lg-center'>
                    <i className='fab fa-facebook-f me-3' />
                    <a
                      href='https://www.facebook.com/Tuempleoidel'
                      target={'_blank'}
                      rel='noreferrer'
                      className='me-4 text-reset'
                    >
                      Facebook
                    </a>
                  </p>
                  <p className='d-block d-md-none text-start text-lg-center'>
                    <i className='fab fa-instagram me-3' />
                    <a
                      href='https://www.instagram.com/tu_empleo_ideal/?hl=es'
                      target={'_blank'}
                      rel='noreferrer'
                      className='me-4 text-reset'
                    >
                      Instagram
                    </a>
                  </p>
                  <p className='d-block d-md-none text-start text-lg-center'>
                    <i className='fab fa-linkedin me-3' />
                    <a
                      href='https://www.linkedin.com/company/tu-empleo-ideal/'
                      target={'_blank'}
                      rel='noreferrer'
                      className='me-4 text-reset'
                    >
                      Linkedin
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className='d-flex justify-content-center justify-content-lg-between p-4 border-top text-muted'>
            {/* Left */}
            <div className='text-center p-4' style={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}}>
              © {new Date().getFullYear()} Copyright:{' '}
              <a className='text-reset fw-bold'>Tu Empleo Ideal</a>
            </div>
            {/* Left */}
            {/* Right */}
            <div className='d-none d-md-flex footer-social'>
              <a
                href='https://www.facebook.com/Tuempleoidel'
                target={'_blank'}
                rel='noreferrer'
                className='me-4 text-reset'
              >
                <i className='fab fa-facebook-f' />
              </a>
              <a
                href='https://www.instagram.com/tu_empleo_ideal/?hl=es'
                target={'_blank'}
                rel='noreferrer'
                className='me-4 text-reset'
              >
                <i className='fab fa-instagram' />
              </a>
              <a
                href='https://www.linkedin.com/company/tu-empleo-ideal/'
                target={'_blank'}
                rel='noreferrer'
                className='me-4 text-reset'
              >
                <i className='fab fa-linkedin' />
              </a>
            </div>
            {/* Right */}
          </section>
        </div>
      </footer>
    </>
  )
}

export {Footer}
