import assert from './assert'
import objectToGetParams from './objectToGetParams'

type Options = {
  /** The url-encoded title value that you wish you use. */
  title?: string
  /** The url-encoded description that you wish you use. */
  summary?: string
  /** The url-encoded source of the content (e.g. your website or application name) */
  source?: string
}

type EmailOptions = {
  body?: string
  separator?: string
  subject?: string
}

export function facebookLink(url: string, {quote, hashtag}: {quote?: string; hashtag?: string}) {
  assert(url, 'facebook.url')

  return (
    'https://www.facebook.com/sharer/sharer.php' +
    objectToGetParams({
      u: url,
      quote,
      hashtag,
    })
  )
}

export function linkedinLink(url: string, {title, summary, source}: Options) {
  assert(url, 'linkedin.url')

  return (
    'https://linkedin.com/shareArticle' +
    objectToGetParams({url, mini: 'true', title, summary, source})
  )
}

export function twitterLink(
  url: string,
  {
    title,
    via,
    hashtags = [],
    related = [],
  }: {title?: string; via?: string; hashtags?: string[]; related?: string[]}
) {
  assert(url, 'twitter.url')
  assert(Array.isArray(hashtags), 'twitter.hashtags is not an array')
  assert(Array.isArray(related), 'twitter.related is not an array')

  return (
    'https://twitter.com/share' +
    objectToGetParams({
      url,
      text: title,
      via,
      hashtags: hashtags.length > 0 ? hashtags.join(',') : undefined,
      related: related.length > 0 ? related.join(',') : undefined,
    })
  )
}

export function emailLink(url: string, {subject, body, separator}: EmailOptions) {
  return 'mailto:' + objectToGetParams({subject, body: body ? body + separator + url : url})
}

function isMobileOrTablet() {
  return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent)
}

export function whatsappLink(
  url: string,
  {title, separator}: {title?: string; separator?: string}
) {
  assert(url, 'whatsapp.url')
  return (
    'https://' +
    (isMobileOrTablet() ? 'api' : 'web') +
    '.whatsapp.com/send' +
    objectToGetParams({
      text: title ? title + separator + url : url,
    })
  )
}
