export const APP_ROLES = {
  ADMIN: 'ADMIN',
  AUTHOR: 'AUTHOR',
}

export const APP_COMPANY_DEFAULT_LOGO = {
  url: 'https://media-exp1.licdn.com/dms/image/C4D0BAQEl-0IOZyy8eA/company-logo_200_200/0/1561560669346?e=2159024400&v=beta&t=NFSn0YuPWzt-o1KM-OGAwmpcvOoyI2o2gqGh-RHWY8I',
}

export const APP_EMAIL_CONTACT_US = process.env.REACT_APP_EMAIL || ''
export const OPENAI_KEY = process.env.REACT_APP_PUBLIC_OPENAI_API_KEY || ''
