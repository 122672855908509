import React, {FC, useEffect, useState} from 'react'
import axios from 'axios'
import * as Yup from 'yup'
import {
  CompanyControllerServiceProxy,
  UpdateCompanyDto,
} from 'app/services/service-proxies/service-proxies'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {Button, Modal, Image, message} from 'antd'
import {ExclamationCircleOutlined} from '@ant-design/icons'

const companyClient = new CompanyControllerServiceProxy(undefined, axios)

type Props = {
  company: any | undefined
  initialValues: any
  isModalVisible: boolean
  setIsModalVisible: (isModalVisible: boolean) => void
  onEditCompany: () => void
}

const registrationSchema = Yup.object().shape({
  _id: Yup.string().required('El id requerido'),
  name: Yup.string()
    .min(3, 'Minimo 3 caracteres')
    .max(50, 'Maximo 50 caracteres')
    .required('El primer nombre es requerido'),
  url: Yup.string().url('Debe ser una URL valida'),
  email: Yup.string().email('Debe ser un email valido').required('El email es requerido'),
  logoUrl: Yup.string().url('Debe ser una URL valida'),
  isActive: Yup.boolean().required('El estado es requerido'),
})

export const EditCompany: FC<Props> = ({
  company,
  initialValues,
  isModalVisible,
  setIsModalVisible,
  onEditCompany,
}) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const {confirm} = Modal

  useEffect(() => {
    setIsModalVisible(isModalVisible)
    if (isModalVisible) {
      formik.setValues(initialValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible])

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      let data: UpdateCompanyDto = new UpdateCompanyDto()
      data.init(values)
      data.name = values.name.toUpperCase()

      confirm({
        title: 'Seguro que deseas editar esta compañía?',
        icon: <ExclamationCircleOutlined />,
        content:
          'Al editar una compañía aceptas que tienes permiso de parte de la compañía para publicar posiciones en su nombre',
        okText: 'Editar',
        cancelText: 'Cancelar',
        onOk() {
          setLoading(true)
          companyClient
            .update(data._id, data)
            .then(() => {
              setLoading(false)
              setIsModalVisible(false)
              message.success('La compañía ha sido editada')
              onEditCompany()
              formik.resetForm()
            })
            .catch((err: any) => {
              setLoading(false)
              setSubmitting(false)
              setStatus(err.message)
            })
        },
      })
    },
  })
  return (
    <Modal
      title={`Editar categoria ${company?.name}`}
      visible={isModalVisible}
      onCancel={() => {
        setIsModalVisible(false)
        document.getElementsByClassName('alert-dismissible')[0].remove()
      }}
      okText='Editar'
      cancelText='Cancelar'
      destroyOnClose={true}
      footer={[
        <Button key='submit' type='primary' loading={loading} onClick={() => formik.handleSubmit()}>
          Editar Compañia
        </Button>,
      ]}
    >
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
      >
        {/* begin::Form group name */}
        <div className='fv-row mb-7'>
          <label className='class="form-label fw-bolder text-dark fs-6 required'>Nombre</label>
          <input
            placeholder='Nombre'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('name')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.name && formik.errors.name,
              },
              {
                'is-valid': formik.touched.name && !formik.errors.name,
              }
            )}
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.name}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
        {/* begin::Form group email */}
        <div className='fv-row mb-7'>
          <label className='class="form-label fw-bolder text-dark fs-6 required'>
            Correo electronico
          </label>
          <input
            placeholder='Correo electronico'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.email && formik.errors.email,
              },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          <div id='companyEmail' className='form-text'>
            Tip: Asegurate de que el correo electronico sea valido, ya que sera el que se utilizara
            para hacerte llegar los curriculum vitae de los candidatos.
          </div>
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
        {/* begin::Form group url */}
        <div className='fv-row mb-7'>
          <label className='class="form-label fw-bolder text-dark fs-6'>Sitio Web (opcional)</label>
          <input
            placeholder='Sitio Web'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('url')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.url && formik.errors.url,
              },
              {
                'is-valid': formik.touched.url && !formik.errors.url,
              }
            )}
          />
          {formik.touched.url && formik.errors.url && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.url}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
        <div className='fv-row mb-7'>
          {/* begin::Form group lastName */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Activo</label>
            <div className='mb-10'>
              <div className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  {...formik.getFieldProps('isActive')}
                  className={clsx(
                    'form-check-input',
                    {
                      'is-invalid': formik.touched.name && formik.errors.name,
                    },
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  type='checkbox'
                  checked={formik.values?.isActive}
                />
              </div>
            </div>
            {formik.touched.isActive && formik.errors.isActive && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.isActive}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
        {/* begin::Form group logoUrl */}
        <div className='fv-row mb-7'>
          <label className='class="form-label fw-bolder text-dark fs-6'>
            Logo de la empresa (.jpg, .png) (opcional)
          </label>
          <div className='input-group mb-3'>
            <input
              placeholder='Logo de la empresa'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('logoUrl')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.logoUrl && formik.errors.logoUrl,
                },
                {
                  'is-valid': formik.touched.logoUrl && !formik.errors.logoUrl,
                }
              )}
            />
            <button
              className='btn btn-outline-primary'
              type='button'
              id='button-addon2'
              disabled={formik?.values?.logoUrl?.length === 0}
              onClick={() => {
                setVisible(true)
              }}
            >
              Visualizar
            </button>
          </div>
          {formik.touched.logoUrl && formik.errors.logoUrl && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.logoUrl}</span>
              </div>
            </div>
          )}

          <div id='logoUrl' className='form-text'>
            Tip: Asegurate de que el servidor donde esté alojado el logo acepte{' '}
            <a
              href='https://es.wikipedia.org/wiki/Hotlinking'
              target='_blank'
              rel='noopener noreferrer'
            >
              hotlinks.
            </a>{' '}
            Si no sabes que es eso, no te preocupes, sube el logo a{' '}
            <a href='https://imgur.com/' target='_blank' rel='noopener noreferrer'>
              imgur
            </a>{' '}
            y pega la url aquí
          </div>
        </div>
        {/* end::Form group */}
      </form>
      <Image
        width={200}
        style={{display: 'none'}}
        src={formik?.values?.logoUrl || ''}
        preview={{
          visible,
          src: formik?.values?.logoUrl || '',
          onVisibleChange: (value) => {
            setVisible(value)
          },
        }}
      />
    </Modal>
  )
}
