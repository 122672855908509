import { HIDE_JOB_MODAL, SHOW_JOB_MODAL } from "./actionTypes";
import { ModalActionTypes } from "./actions";

interface ModalState {
    showModal: boolean;
}

const initialState: ModalState = {
    showModal: false,
};

const jobReducer = (state = initialState, action: ModalActionTypes): ModalState => {
    switch (action.type) {
        case SHOW_JOB_MODAL:
            return {
                ...state,
                showModal: true,
            };
        case HIDE_JOB_MODAL:
            return {
                ...state,
                showModal: false,
            };
        default:
            return state;
    }
};

export default jobReducer;