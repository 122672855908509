/* eslint-disable jsx-a11y/anchor-is-valid */
import {TimeAgo} from 'app/shared/components/TimeAgo'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {APP_COMPANY_DEFAULT_LOGO} from 'setup/app-constants'
import {RootState} from 'setup'
import {UserModel} from 'app/modules/auth/models/UserModel'
import {APP_ROLES} from 'setup/app-constants'
import './CardJob.scss'

type Props = {
  job: any
}

export const CardJob: React.FC<Props> = ({job}) => {
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const navigate = useNavigate()

  return (
    <div className='single-job-items mb-30 rounded'>
      <div className='job-items'>
        <div className='company-img'>
          <a>
            <img
              src={job?.company?.logoUrl ? job?.company?.logoUrl : APP_COMPANY_DEFAULT_LOGO.url}
              alt=''
            />
          </a>
        </div>
        <div className='job-tittle'>
          <a onClick={() => navigate(`/ofertas-de-empleo/${job.slug}`)}>
            <h4>{job?.title.length > 45 ? job?.title.slice(0, 45) + '...' : job?.title}</h4>
          </a>
          <ul>
            <li>{job?.company?.name}</li>
            <li>
              <i className='fas fa-map-marker-alt' />
              {job?.location?.name}
            </li>
            <li>
              {job.isRemote ? (
                <span className='badge badge-info fs-9 ms-2'>Remoto</span>
              ) : (
                <span className='badge badge-primary fs-9 ms-2'>Presencial</span>
              )}
            </li>
            {isAuthorized?.roles?.includes(APP_ROLES.ADMIN) && (
              <li>
                <i className='fas fa-eye me-2'></i>
                {job?.viewCount}
                <i className='fas fa-paper-plane ms-2 me-2'></i>
                {job?.requested}
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className='items-link f-right'>
        <a onClick={() => navigate(`/ofertas-de-empleo/${job.slug}`)}>
          {job?.hyreType?.name} <i className='fas fa-arrow-right ms-4'></i>
        </a>
        <span className='d-none d-md-block' style={{fontSize: '20px !important'}}>
          <TimeAgo time={job.publishedFrom} />
        </span>
      </div>
    </div>
  )
}
