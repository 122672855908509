import firebase from 'firebase/compat/app'
import {getFirestore} from 'firebase/firestore'
import {GoogleAuthProvider, getAuth, signInWithPopup} from 'firebase/auth'
import {initializeApp} from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyD60IMBDRwUiW0jivEOc4aMGTsQtBliIrc',
  authDomain: 'tuempleoideal-8b41c.firebaseapp.com',
  projectId: 'tuempleoideal-8b41c',
  storageBucket: 'tuempleoideal-8b41c.appspot.com',
  messagingSenderId: '1014687571506',
  appId: '1:1014687571506:web:a93d6aa2667c844f4ba62b',
  measurementId: 'G-DR1GBRQYJ1',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

const db = getFirestore(app)
const auth = getAuth(app)
auth.languageCode = 'es'
const googleAuthProvider = new GoogleAuthProvider()

export {db, googleAuthProvider, firebase, auth, signInWithPopup}
