import React from 'react'
import axios from 'axios'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Modal, Button, Select } from 'antd'
import clsx from 'clsx'
import { CreateLinkDto, LinkControllerServiceProxy, UpdateLinkDto } from 'app/services/service-proxies/service-proxies'
import { LinkType } from '../../shared/constants/linkTypes'

const linkClient = new LinkControllerServiceProxy(undefined, axios)

type Props = {
    isVisible: boolean
    setIsVisible: (isModalVisible: boolean) => void
    onCreate: () => void,
    updateLink?: any
}

const initialValues = {
    name: '',
    icon: '',
    url: '',
    linkType: null as number | null,
}

const registrationSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, 'Minimo 3 caracteres')
        .max(50, 'Maximo 50 caracteres')
        .required('El nombre es requerido'),
    icon: Yup.string(),
    url: Yup.string().url('El url no es valido').required('El url es requerido'),
    linkType: Yup.number().required('El tipo de link es requerido').nullable(true),
})

export const CreateLink: React.FC<Props> = ({
    isVisible,
    setIsVisible,
    onCreate,
    updateLink
}) => {
    const [loading, setLoading] = React.useState(false)
    const [linkTypes, setLinkTypes] = React.useState<Array<any>>([])

    const formik = useFormik({
        initialValues,
        validationSchema: registrationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            let data: CreateLinkDto = new CreateLinkDto()
            data.init(values)

            if (updateLink) {
                let dataUpdate: UpdateLinkDto = new UpdateLinkDto()
                dataUpdate.init(values)
                dataUpdate._id = updateLink._id
                linkClient
                    .update(updateLink._id, dataUpdate)
                    .then(() => {
                        setLoading(false)
                        handleCancel();
                        onCreate()
                    })
                    .catch((err: any) => {
                        setLoading(false)
                        setSubmitting(false)
                        setStatus(err.message)
                    })
                return
            }

            linkClient
                .create(data)
                .then(() => {
                    setLoading(false)
                    handleCancel();
                    onCreate()
                })
                .catch((err: any) => {
                    setLoading(false)
                    setSubmitting(false)
                    setStatus(err.message)
                })
        },
    })

    React.useEffect(() => {
        getLinkTypes()
    }, [])

    React.useEffect(() => {
        switch (formik.values.linkType) {
            case LinkType.Facebook:
                formik.setFieldValue('icon', 'fa-brands fa-facebook text-primary')
                break
            case LinkType.Instagram:
                formik.setFieldValue('icon', 'fab fa-instagram-square text-warning')
                break
            case LinkType.Twitter:
                formik.setFieldValue('icon', 'fab fa-twitter-square text-info')
                break
            case LinkType.LinkedIn:
                formik.setFieldValue('icon', 'fab fa-linkedin text-primary')
                break
            case LinkType.Youtube:
                formik.setFieldValue('icon', 'fab fa-youtube text-danger')
                break
            case LinkType.Tiktok:
                formik.setFieldValue('icon', 'fab fa-tiktok text-dark')
                break
            case LinkType.Snapchat:
                formik.setFieldValue('icon', 'fab fa-snapchat-square')
                break
            case LinkType.Pinterest:
                formik.setFieldValue('icon', 'fab fa-pinterest-square')
                break
            case LinkType.Webside:
                formik.setFieldValue('icon', 'fab fa-chrome')
                break
            default:
                formik.setFieldValue('icon', '')
                break
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.linkType])

    const getLinkTypes = async () => {
        const result: any = await linkClient
            .getAllLinkType()
            .catch((err) => {
                console.log(err)
            })
        if (result) {
            setLinkTypes(result.data)
        }
    }

    const handleCancel = () => {
        setIsVisible(false)
        formik.resetForm()
    }

    React.useEffect(() => {
        formik.setValues({
            ...formik.values,
            name: updateLink?.name ?? '',
            icon: updateLink?.icon ?? '',
            url: updateLink?.url ?? '',
            linkType: updateLink?.linkType ?? null,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateLink])

    return (
        <Modal
            title='Crear enlace'
            visible={isVisible}
            onCancel={handleCancel}
            okText='Editar'
            cancelText='Cancelar'
            destroyOnClose={true}
            footer={[
                <Button key='back' onClick={handleCancel}>
                    Cancelar
                </Button>,
                <Button key='submit' type='primary' loading={loading} onClick={() => formik.handleSubmit()}>
                    Guardar
                </Button>,
            ]}
        >
            <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_signup_form'
            >
                <div className='fv-row mb-7'>
                    <label className='class="form-label fw-bolder text-dark fs-6 required'>Nombre</label>
                    <input
                        placeholder='Nombre'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('name')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                                'is-invalid': formik.touched.name && formik.errors.name,
                            },
                            {
                                'is-valid': formik.touched.name && !formik.errors.name,
                            }
                        )}
                    />
                    {formik.touched.name && formik.errors.name && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.name}</span>
                            </div>
                        </div>
                    )}
                </div>
                <div className='fv-row mb-7'>
                    <label className='class="form-label fw-bolder text-dark fs-6'>Icono</label>
                    <input
                        placeholder='Icono'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('icon')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                                'is-invalid': formik.touched.icon && formik.errors.icon,
                            },
                            {
                                'is-valid': formik.touched.icon && !formik.errors.icon,
                            }
                        )}
                    />
                    {formik.touched.icon && formik.errors.icon && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.icon}</span>
                            </div>
                        </div>
                    )}
                </div>
                <div className='fv-row mb-7'>
                    <label className='class="form-label fw-bolder text-dark fs-6 required'>Enlace</label>
                    <input
                        placeholder='Enlace'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('url')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                                'is-invalid': formik.touched.url && formik.errors.url,
                            },
                            {
                                'is-valid': formik.touched.url && !formik.errors.url,
                            }
                        )}
                    />
                    {formik.touched.url && formik.errors.url && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.url}</span>
                            </div>
                        </div>
                    )}
                </div>
                <div className='fv-row mb-7'>
                    <label className='class="form-label fw-bolder text-dark fs-6 required mb-2'>Tipo de enlace</label>
                    <Select className='form-control form-control-lg'
                        showSearch
                        placeholder="Selecciona un tipo de enlace"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={linkTypes}
                        onChange={(value) => formik.setFieldValue("linkType", value)}
                        value={formik.values.linkType}
                    />
                    {formik.touched.linkType && formik.errors.linkType && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.linkType}</span>
                            </div>
                        </div>
                    )}
                </div>
            </form>
        </Modal>
    )
}
